* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #171717;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.notification-enter {
    opacity: 0;
    transform: translateX(100%);
}

.notification-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.notification-exit {
    opacity: 1;
    transform: translateX(0%);
}

.notification-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}
